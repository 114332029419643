.swiper.banner {
    .swiper-pagination-bullet {
        background: #e8e9eb;
    }
    .swiper-slide {
        @media only screen and (max-width: 48rem) {
            padding: 2.75rem 1.25rem 65px;
        }
    }
}
.swiper.step, .swiper.banner{
    .swiper-pagination-bullet {
        opacity: 0.6;
    }
    .swiper-pagination-bullet-active {
        border-radius: 1.25rem;
        width: 1.875rem;
        opacity: 1;
    }
    .swiper-slide {
        padding: 1.5rem 2.5rem 4.5rem;
    }
}
.swiper.step {
    .swiper-pagination-bullet {
        background: #002612;
    }
    .swiper-slide {
        @media only screen and (max-width: 48rem) {
            padding: 1.25rem 0rem;
        }
    }
}
